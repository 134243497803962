import { Checkbox, Tooltip } from '@chakra-ui/react';
import {
  chakraComponents,
  OptionProps as ReactSelectOptionProps,
} from 'chakra-react-select';

interface OptionProps extends ReactSelectOptionProps {}

const Option = ({
  isSelected,
  label,
  isFocused,
  isMulti,
  isDisabled,
  ...rest
}: OptionProps) => {
  const {
    selectProps: { disabledTooltipLabel },
  } = rest;
  return (
    <Tooltip
      label={disabledTooltipLabel}
      bg="gray.900"
      maxW="none"
      offset={[300, -35]}
      borderRadius="md"
      isDisabled={!isDisabled}>
      <span data-testid={`select_option_${label}`}>
        <chakraComponents.Option
          {...rest}
          isMulti={isMulti}
          isSelected={isSelected}
          label={label}
          isFocused={isFocused}
          isDisabled={isDisabled || isSelected}>
          {isMulti ? (
            <Checkbox
              fontWeight="bold"
              isChecked={isSelected}
              data-testid={`checkbox_${label}`}
              size="md"
              width="100%">
              {label}
            </Checkbox>
          ) : (
            <>
              {label}
              {isDisabled && !!disabledTooltipLabel && ' ＊'}
            </>
          )}
        </chakraComponents.Option>
      </span>
    </Tooltip>
  );
};
export default Option;
