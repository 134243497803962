import { useColorModeValue, useTheme } from '@chakra-ui/react';
import { Props as SelectProps } from 'chakra-react-select';
import colors from '~/theme/colors';
import useInputBgColor from 'hooks/themeColors/useInputBgColor';
import usePrimaryTextThemeColor from 'hooks/themeColors/usePrimaryTextThemeColor';

export interface UseSelectCustomStyleProps {
  height?: string;
  width?: string;
  keepPlaceholderAfterTyping?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  controlFlex?: boolean;
  clearIndicatorTop?: string;
}

const SELECT_HEIGHT = '48px';

const useSelectCustomStyle = () => {
  const secondaryTextColor = useColorModeValue('gray.870', 'gray.800');
  const borderColor = useColorModeValue('gray.800.o60', 'gray.700');
  const chakraTheme = useTheme();
  const selectBgColor = useInputBgColor();
  const inputTextColor = usePrimaryTextThemeColor();

  const getSelectCustomStyles = ({
    height = SELECT_HEIGHT,
    isDisabled = false,
    hasError = false,
    keepPlaceholderAfterTyping = true,
    clearIndicatorTop = '0',
  }: UseSelectCustomStyleProps = {}): SelectProps['styles'] => ({
    control: provided => ({
      ...provided,
      minHeight: height,
      width: '100%',
      borderRadius: '0px',
      borderColor: hasError ? colors.brand.error['dark.035'] : borderColor,
      backgroundColor: hasError ? colors.brand.error['dark.035'] : '',
      display: 'flex',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      fontSize: '14px',
      color: secondaryTextColor,
      display: 'none',
      opacity: isDisabled ? 0.3 : 1,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    container: provided => ({
      ...provided,
      width: '100%',
      height: '100%',
    }),
    valueContainer: provided => ({
      ...provided,
      lineHeight: '14px',
      alignItems: 'end',
      paddingBottom: '0px',
      paddingTop: keepPlaceholderAfterTyping ? '20px' : '',
    }),
    singleValue: provided => ({
      ...provided,
      top: '',
      marginLeft: '0px',
      paddingBottom: '2px',
      // @viniraja-glasfunds TODO remove the gridArea in component library
      gridArea: '1/1/3',
    }),
    clearIndicator: provided => ({
      ...provided,
      padding: 0,
      color: 'gray.800',
      fontSize: '8px',
      position: 'relative',
      '&:hover': {
        bg: 'transparent',
        color: 'brand.error.light',
      },
      top: clearIndicatorTop,
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      minH: '45px',
      fontSize: 'md',
      backgroundColor:
        state.isFocused || state?.isSelected
          ? chakraTheme.colors.brand.perwinkle[500]
          : selectBgColor,
      alignItems: 'center',
      color: state.isFocused ? 'white' : inputTextColor,
    }),
    menu: provided => ({
      ...provided,
      margin: '0px',
      borderWidth: '1px',
    }),
    input: provided => ({
      ...provided,
      fontWeight: 'bold',
    }),
  });
  return { getSelectCustomStyles };
};

export default useSelectCustomStyle;
