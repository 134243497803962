import {
  Box,
  Text,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import colors from 'theme/colors';
import useBgThemeColor from '~/hooks/themeColors/useBgThemeColor';
import { TOAST_STATUSES_STYLE } from './toastStatusesStyle';

export type CustomToastProps = {
  title: string;
  status?: 'success' | 'error' | 'warning' | 'info';
  description?: string;
  onClose: () => void;
  onClick?: () => void;
};

const CustomToast = ({
  status = 'info',
  title,
  description = '',
  onClose,
  onClick,
}: CustomToastProps) => {
  const toastStyle = TOAST_STATUSES_STYLE[status];

  const color = useColorModeValue(colors.gray[900], toastStyle.color);
  const bgColor = useBgThemeColor();

  const handleToastClick = () => {
    onClick?.();
    onClose();
  };

  return (
    <Box bg={bgColor}>
      <HStack
        p="0"
        py="4"
        pr="4"
        bg={`${toastStyle.bg}1A`}
        borderBottom={`2px solid ${toastStyle.color}`}
        onClick={handleToastClick}
        cursor="pointer">
        <Icon
          as={toastStyle.icon}
          fontSize="2xl"
          color={toastStyle.color}
          mx="10px !important"
        />
        <VStack alignItems="start" flex="1">
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="sm"
            color={color}>
            {title}
          </Text>
          <Text color="gray.870" fontSize="sm">
            {description}
          </Text>
        </VStack>

        <Icon
          onClick={onClose}
          as={AiOutlineClose}
          fill={color}
          cursor="pointer"
          alignSelf="center"
          ml="20px !important"
        />
      </HStack>
    </Box>
  );
};

export default CustomToast;
